@import '../../styles//mixins.scss';


.canvasHolder {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;

    @include phone {
        overflow-y: auto;
        max-width: 100;
    }
}

.uiHolder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    right: 0;
    gap: 40px;

    .textSection {
        margin-top: 20%;
        max-width: 35%;
        text-align: left;
        padding-bottom: 50px;

        h1 {
            // font-weight: 500;
            font-size: 2.65em;
            margin-bottom: 30px;
        }

        p {
            font-size: 18px;
            animation-delay: .2s !important;
            // line-height: 24px;
            margin-bottom: 30px;
        }

        @include laptop-s() {
            margin-top: 12%;
            max-width: 45%;
        }

        @include iPadAir() {
            max-width: 75%;
        }

        @include phone {
            margin-top: 45%;
            max-width: 80%;
        }
    }

    .emailForm {
        width: 100%;
    }

}